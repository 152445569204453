import logo from '../assets/logo.png'

const Logo = () => {
  return (
    <div className="logo">
      <img src={logo} alt="" />
      <h4>
        Distro
      </h4>
    </div>
  )
}

export default Logo